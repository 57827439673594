import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { IMessage } from "../../../interfaces/CommonInterfaces";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

export const ContactFormComponent = () => {
  const form = useRef<HTMLFormElement>(null);
  // eslint-disable-next-line no-useless-escape
  const emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const lang = useReadLocalStorage("lang");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height } = useWindowDimensions();
  const [data, setData] = useState<IMessage>({
    fullname: "",
    email: "",
    message: "",
  });

  const notifySuccess = () => {
    toast.success(
      lang === "EN"
        ? "Message correctly sent!"
        : "Messaggio inviato correttamente!",
      {
        position: width < 768 ? "top-center" : "bottom-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };

  const notifyFailure = (val?: string) => {
    toast.error(
      val
        ? val
        : lang === "EN"
        ? "Oops! Something went wrong. \nTry again later"
        : "Oops! QUalcosa è andato storto.\nRiprova più tardi.",
      {
        position: width < 768 ? "top-center" : "bottom-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      }
    );
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!data.email.match(emailRegExp)) {
      notifyFailure(
        lang === "EN"
          ? "The provided e-mail address isn't valid!"
          : "L'indirizzo e-mail inserito non è valido!"
      );
      return;
    } else {
      if (data.message.length < 15) {
        notifyFailure(
          lang === "EN"
            ? "The message must be over 15 characters!"
            : "Il messaggio deve essere lungo almeno 15 caratteri!"
        );
        return;
      }
    }

    if (
      process.env.REACT_APP_EMAIL_JS_SERVICE_ID &&
      process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID &&
      process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
    ) {
      form.current &&
        emailjs
          .sendForm(
            process.env.REACT_APP_EMAIL_JS_SERVICE_ID,
            process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID,
            form.current,
            process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY
          )
          .then(
            (result) => {
              result.text === "OK" && notifySuccess();
              form.current?.reset();
            },
            (error) => {
              notifyFailure();
            }
          );
    }
  };

  const defineHeight = () => {
    if (width >= 992 && width < 1200) {
      return "115px";
    } else if (width >= 1200) {
      return "175px";
    } else {
      return "200px";
    }
  };

  return (
    <Form
      ref={form}
      className="actual-form p-3 px-sm-4 px-xl-5 h-100"
      onSubmit={(e) => sendEmail(e)}
      noValidate
    >
      <h2>{lang === "EN" ? "Contact Me:" : "Contattami"}</h2>
      <div>
        <FloatingLabel
          controlId="floatingNameAndSurname"
          label={lang === "EN" ? "Fullname" : "Nome e cognome"}
        >
          <Form.Control
            type="text"
            placeholder="Fullname"
            className="shadow-none"
            name="user_name"
            value={data.fullname}
            onChange={(e) =>
              setData({
                ...data,
                fullname: e.target.value,
              })
            }
          />
        </FloatingLabel>
        <FloatingLabel controlId="floatingInput" label="Email">
          <Form.Control
            type="email"
            placeholder="name@example.com"
            className="shadow-none"
            name="user_email"
            value={data.email}
            onChange={(e) =>
              setData({
                ...data,
                email: e.target.value,
              })
            }
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="floatingTextarea2"
          label={lang === "EN" ? "Message" : "Messaggio"}
        >
          <Form.Control
            as="textarea"
            placeholder="Write here your message"
            className="shadow-none"
            style={{ height: defineHeight(), maxHeight: defineHeight() }}
            name={"message"}
            value={data.message}
            onChange={(e) =>
              setData({
                ...data,
                message: e.target.value,
              })
            }
          />
        </FloatingLabel>
      </div>
      <Button type="submit" variant="outline-dark" className="py-3 px-5">
        {lang === "EN" ? "SEND MESSAGE" : "INVIA MESSAGGIO"}
      </Button>
    </Form>
  );
};
