import { AppWrapperComponent } from "./components/appwrapper/AppWrapperComponent";
import { BrowserRouter } from "react-router-dom";
import "./App.scss";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

function App() {
  if (process.env.NODE_ENV === "production") {
    disableReactDevTools();
  }

  return (
    <>
      <BrowserRouter>
        <AppWrapperComponent />
      </BrowserRouter>
    </>
  );
}

export default App;
