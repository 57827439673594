import { Button, Col, Container, Row } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";

export const AboutMeComponent = () => {
  const navigate = useNavigate();
  const lang = useReadLocalStorage("lang");

  return (
    <Container className="d-flex align-items-center home-second-container">
      <Row className="d-flex align-items-center">
        <Col xs={11} sm={8} md={7} lg={6} xl={5} className="ps-4">
          <h1>{lang === "EN" ? "About me" : "Chi sono"}</h1>
          <p className="mt-3">{lang === "EN" ? "Tenor" : "Tenore"}</p>

          <p className="mt-4 biography-slice">
            {lang === "EN"
              ? `Italian tenor Omar Mancini graduated in clarinet in 2016 at the Conservatorio Giuseppe Verdi in 
              Milan under the guidance of Luigi Magistrelli. Concurrently, he began studying opera singing with 
              Davide Gualtieri, where he continues under the guidance of Gianluca Valenti. 
              In 2017, he was admitted in the First diploma level Vocal Chamber Music course under the guidance 
              of Daniela Uccello.`
              : `Il tenore italiano, Omar Mancini, si è laureato in clarinetto nel 2016 al Conservatorio Giuseppe 
              Verdi di Milano sotto la guida di Luigi Magistrelli. Nel contempo, ha iniziato a studiare canto 
              d'opera con Davide Gualtieri, per poi continuare sotto la guida di Gianluca Valenti.
              Nel 2017, è stato ammesso al È stato ammesso nel corso di musica per la camera vocale al primo livello di 
              diploma sotto la guida di Daniela Uccello.`}
          </p>
          <Button
            variant="outline-light"
            className="mt-5 d-flex align-items-center justify-content-center px-5 py-4 about-me-btn"
            onClick={() => navigate("/about-me")}
          >
            {lang === "EN" ? "DISCOVER" : "SCOPRI"}
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
