import { INews } from "../../../../interfaces/CommonInterfaces";
import { Badge, Button, Card } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";
import { BiNews } from "react-icons/bi";
import { Link } from "react-router-dom";
import { isBefore, isWithinInterval } from "date-fns";

interface newsProps {
  news: INews;
}

export const NewsCardComponent = ({ news }: newsProps) => {
  const lang = useReadLocalStorage("lang");

  const definePeriod = () => {
    const start = new Date(news.from_date);
    const end = new Date(news.to_date);
    const today = new Date(Date.now());

    if (isBefore(end, today)) {
      return lang === "EN" ? "Past" : "Passata";
    } else {
      if (
        isWithinInterval(today, {
          start: start,
          end: end,
        })
      ) {
        return lang === "EN" ? "On-Going" : "In Corso";
      } else {
        return lang === "EN" ? "Coming-Soon" : "Prossimamente";
      }
    }
  };

  return (
    <>
      <Card className="news-card px-3 py-2">
        <Card.Header className="d-flex flex-column align-items-start p-0 mb-4">
          <div className="d-flex align-items-center justify-content-center icon-news-container my-3">
            <BiNews className="news-icon" />
          </div>
          <div className="news-title-container position-relative">
            <h4>{news.opera_title}</h4>
            <p className="ps-5 m-0">
              {lang === "EN" ? "by " : "di "}
              {news.opera_author}
            </p>
            <Badge bg="dark">{definePeriod()} </Badge>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="m-0 pe-2">{lang === "EN" ? "From" : "Da"}:</h6>
            <Badge bg="dark">
              <p className="m-0">{news.from_date}</p>
            </Badge>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="m-0 pe-2">{lang === "EN" ? "To" : "A"}:</h6>
            <Badge bg="dark">
              <p className="m-0">{news.to_date}</p>
            </Badge>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="m-0 pe-2">{lang === "EN" ? "Role" : "Ruolo"}:</h6>
            <p className="m-0">{news.role}</p>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h6 className="m-0 pe-2">
              {lang === "EN" ? "Location" : "Luogo"}:
            </h6>
            <p className="m-0">{news.location}</p>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-start ps-3 pt-2">
          <Link to={news.path} target="_blank" className="w-100">
            <Button
              variant="outline-dark"
              className={`${lang !== "EN" && "px-5"}`}
            >
              {lang === "EN" ? "READ MORE" : "LEGGI"}
            </Button>
          </Link>
        </Card.Footer>
      </Card>
    </>
  );
};
