import { FaFacebookSquare, FaYoutube } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export const SocialComponent = () => {
  return (
    <Row className="social-row mx-0 my-5 ">
      <Col
        xs={4}
        className="social-icon-container left d-flex align-items-center justify-content-center"
      >
        <Link to="https://www.facebook.com/omar.mancini">
          <FaFacebookSquare className="social-large-icon" />
        </Link>
      </Col>
      <Col
        xs={4}
        className="social-icon-container middle d-flex align-items-center justify-content-center"
      >
        <Link to="https://youtube.com/@omarmancini160">
          <FaYoutube className="social-large-icon" />
        </Link>
      </Col>
      <Col
        xs={4}
        className="social-icon-container right d-flex align-items-center justify-content-center"
      >
        <Link to="https://www.operabase.com/artists/omar-mancini-2116869/it">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            viewBox="0 0 512 512"
            className="opera-base-icon"
          >
            <path
              className="svg-to-fill"
              opacity="1.000000"
              stroke="none"
              id="svg-to-fill"
              d="
              M513.000000,328.531342 
                C511.179291,328.902191 509.358612,328.719147 507.537903,328.718842 
                C421.901489,328.704803 336.265076,328.735260 250.628830,328.617279 
                C246.586029,328.611694 244.601562,329.703003 242.927780,333.565826 
                C234.678986,352.602753 225.975372,371.441956 217.531845,390.395294 
                C204.428986,419.807404 173.901962,437.160553 142.693604,432.839935 
                C110.738800,428.415985 86.030663,403.357117 81.793571,371.075409 
                C76.189522,328.379028 109.122948,290.556793 152.229446,290.312836 
                C173.721161,290.191193 195.214523,290.228821 216.706085,290.362274 
                C219.774521,290.381287 221.379471,289.591095 222.680649,286.638672 
                C231.516830,266.588928 240.579788,246.639175 249.556824,226.651413 
                C249.881119,225.929382 250.073761,225.148224 250.546982,223.744247 
                C167.347855,223.744247 84.627472,223.744247 1.453548,223.872131 
                C1.000000,211.312439 1.000000,198.624878 1.000000,185.468658 
                C2.634370,185.162262 4.268701,185.466049 5.903114,185.466492 
                C91.559624,185.489471 177.216187,185.455093 262.872498,185.586807 
                C266.866547,185.592972 268.631958,184.335678 270.208618,180.733536 
                C278.661896,161.420593 287.355438,142.211227 296.117981,123.035561 
                C309.318726,94.147339 339.595490,77.059212 369.978394,81.196266 
                C402.200195,85.583717 427.111267,110.202118 431.768311,142.260223 
                C438.001831,185.170364 404.561493,223.479263 360.442200,223.808456 
                C339.279236,223.966370 318.113586,223.909622 296.950500,223.758148 
                C293.683868,223.734772 292.065094,224.579437 290.681458,227.727585 
                C281.799225,247.936783 272.633148,268.021210 263.575500,288.153473 
                C263.320129,288.721039 263.234283,289.364868 262.950226,290.412781 
                C346.226868,290.412781 429.184448,290.412781 512.571045,290.206390 
                C513.000000,302.687561 513.000000,315.375122 513.000000,328.531342 
              M332.181030,135.798615 
                C324.772583,152.176941 317.364136,168.555252 309.852478,185.161774 
                C328.775299,185.161774 347.082886,186.016968 365.274719,184.931778 
                C385.215149,183.742279 398.703674,162.914581 393.256317,143.698608 
                C389.391113,130.063843 380.362579,121.586212 366.538116,119.337952 
                C352.001343,116.973869 340.594879,122.813553 332.181030,135.798615 
              M152.946121,328.486816 
                C152.280655,328.512451 151.612610,328.509064 150.950104,328.568054 
                C133.436356,330.127075 120.617081,342.587677 119.160759,359.460480 
                C117.780037,375.457520 128.885010,390.338593 145.181610,394.329498 
                C160.843948,398.165039 176.514404,389.722961 183.467712,373.790100 
                C186.985626,365.729187 190.640350,357.727997 194.230865,349.698730 
                C197.299026,342.837555 200.363831,335.974884 203.710464,328.485565 
                C186.456055,328.485565 170.163696,328.485565 152.946121,328.486816 
              z"
            />
          </svg>
        </Link>
      </Col>
    </Row>
  );
};
