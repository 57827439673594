import ItalyFlag from "../../assets/images/Photos/Others/flag-for-flag-italy-svgrepo-com.svg";
import EnglandFlag from "../../assets/images/Photos/Others/united-kingdom-uk-svgrepo-com.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useLocalStorage } from "usehooks-ts";

interface DropdownCollapseProps {
  collapsed: boolean;
  setCollapsed: (bool: boolean) => void;
}

export const LangDropdownComponent = ({
  collapsed,
  setCollapsed,
}: DropdownCollapseProps) => {
  const [lang, setLang] = useLocalStorage("lang", "IT");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height } = useWindowDimensions();

  return (
    <DropdownButton
      className={`lang-dropdown ${width < 476 && "my-3"} ${
        width > 476 && "mx-3"
      }`}
      variant="outline"
      title={
        lang === "IT" ? (
          <span>
            IT{" "}
            <img
              style={{ width: 25, marginLeft: 5 }}
              src={ItalyFlag}
              alt="Italy icon flag"
            />
          </span>
        ) : (
          <span>
            EN{" "}
            <img
              style={{ width: 25 }}
              src={EnglandFlag}
              alt="England icon flag"
            />
          </span>
        )
      }
    >
      <Dropdown.Item
        href="#"
        onClick={() => {
          setLang("IT");
          window.scrollTo(0, 0);
          width < 476 && setCollapsed(!collapsed);
        }}
      >
        Italiano
      </Dropdown.Item>
      <Dropdown.Item
        href="#"
        onClick={() => {
          setLang("EN");
          window.scrollTo(0, 0);
          width < 476 && setCollapsed(!collapsed);
        }}
      >
        English
      </Dropdown.Item>
    </DropdownButton>
  );
};
