import { AboutContactComponent } from "./about_contact/AboutContactComponent";
import { AboutPathComponent } from "./about_path/AboutPathComponent";
import { AboutBioComponent } from "./about_bio/AboutBioComponent";
import { LoadingProps } from "../../interfaces/CommonInterfaces";
import { Col, Container, Row } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";
import { useState, useEffect } from "react";

export const AboutMePageComponent = ({ isLoading }: LoadingProps) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const lang = useReadLocalStorage("lang");

  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <>
      {isReady ? (
        <>
          <Row
            className={`about-me-top-row py-5 mx-0 ${isLoading && "opacity-0"}`}
          >
            <Col xs={12} className=" px-4 py-1 py-md-5 text-center">
              <h2 className="m-0">{lang === "EN" ? "About:" : "Chi è:"}</h2>
              <h2 className="m-0">Omar Mancini</h2>
            </Col>
          </Row>
          <Container className="about-me-container">
            <Row className="bio-about-me">
              <AboutBioComponent />
            </Row>
            <hr />
            <Row className="bio-by-year-row d-flex ">
              <h3 className="mb-4">
                {lang === "EN" ? "The Path" : "Il Percorso"}
              </h3>
              <AboutPathComponent />
            </Row>
            <Row className="d-flex justify-content-center">
              <AboutContactComponent />
            </Row>
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
