import Map from "../../assets/images/Photos/Others/location.png";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import Logo from "../../assets/images/Logo/Logo small.png";
import { Link, useLocation } from "react-router-dom";
import { useReadLocalStorage } from "usehooks-ts";
import { useState, useEffect } from "react";

export const FooterComponent = () => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const lang = useReadLocalStorage("lang");
  const location = useLocation().pathname;

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <>
      {isReady ? (
        <Row className={"footer-row p-0 m-0  pb-4"}>
          <Container className="py-5">
            <Row className="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-center">
              <Col
                xs={12}
                className="d-flex align-items-center justify-content-center mb-5"
              >
                <img
                  src={Logo}
                  alt="Omar Mancini Logo"
                  className="footer-logo"
                />
              </Col>
              <Col
                xs={6}
                md={4}
                xl={3}
                className="footer-recap d-flex flex-column align-items-center justify-content-start"
              >
                <h6>{lang === "EN" ? "Content" : "Contenuti"}</h6>
                <ListGroup className="align-items-center">
                  {location === "/homepage" ? (
                    <ListGroup.Item
                      className="p-0"
                      onClick={() => window.scroll(0, 1500)}
                    >
                      {lang === "EN" ? "News" : "Notizie"}
                    </ListGroup.Item>
                  ) : (
                    <Link to="/homepage">
                      <ListGroup.Item className="p-0">
                        {lang === "EN" ? "News" : "Notizie"}
                      </ListGroup.Item>
                    </Link>
                  )}
                  <Link to="/gallery">
                    <ListGroup.Item className="p-0">
                      {lang === "EN" ? "Gallery" : "Galleria"}
                    </ListGroup.Item>
                  </Link>
                  <Link to="/about-me">
                    <ListGroup.Item className="p-0">
                      {lang === "EN" ? "About me" : "Chi sono"}
                    </ListGroup.Item>
                  </Link>
                  <ListGroup.Item
                    className="p-0"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {lang === "EN" ? "Back to top" : "Torna su"}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col
                xs={6}
                md={4}
                xl={3}
                className="footer-contacts d-flex flex-column align-items-center justify-content-start"
              >
                <h6>{lang === "EN" ? "Contacts" : "Contatti"}</h6>
                <ListGroup className="align-items-center">
                  <Link to="/about-me">
                    <ListGroup.Item className="p-0">
                      FN: Omar Mancini
                    </ListGroup.Item>
                  </Link>
                  <Link to="tel:+39 340-9920 245">
                    <ListGroup.Item className="p-0">
                      Tel: +39 340-9920 245
                    </ListGroup.Item>
                  </Link>
                  <Link to="mailto:manciniomardc@gmail.com">
                    <ListGroup.Item className="p-0">
                      @:{" "}
                      <span className="list-email">
                        manciniomardc@gmail.com
                      </span>
                    </ListGroup.Item>
                  </Link>
                </ListGroup>
              </Col>
              <Col
                xs={5}
                md={4}
                xl={3}
                className="footer-location d-flex flex-column align-items-center justify-content-start "
              >
                <h6 className="location-header">
                  Milan (MI), Lombardia, Italy - IT
                </h6>
                <Link to="https://www.google.com/maps/place/Milano+MI/data=!4m2!3m1!1s0x4786c1493f1275e7:0x3cffcd13c6740e8d?sa=X&ved=2ahUKEwje2any2dL_AhUES_EDHcxhAUEQ8gF6BAgzEAE">
                  <img src={Map} alt="Map of milan" />
                </Link>
              </Col>
            </Row>
          </Container>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};
