import { Button, Col, Row } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";

export const BiographyComponent = () => {
  const navigate = useNavigate();
  const lang = useReadLocalStorage("lang");

  return (
    <Row className="p-0 mx-0 d-lg-flex justify-content-center mb-4 bio-row-home">
      <Col
        xs={12}
        lg={6}
        className="p-0 mb-4 mb-lg-0 d-flex justify-content-center justify-content-lg-start"
      >
        <div className="bio-left-container"></div>
      </Col>
      <Col
        xs={12}
        lg={6}
        className="p-0 d-flex justify-content-center justify-content-lg-end"
      >
        <div
          className={`bio-right-container ${
            lang !== "EN" ? "pt-4 pt-xl-5" : "pt-5"
          } ps-2 pe-3 pb-4`}
        >
          <h3 className="px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5">Omar Mancini</h3>
          <p className="px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5 ">
            {lang === "EN" ? "Biography" : "Biografia"}
          </p>
          <p
            className={`bio-slice-p ${
              lang !== "EN" && "italiano"
            } px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5 mb-0`}
          >
            {lang === "EN"
              ? `Italian tenor Omar Mancini graduated in clarinet in 2016 at the
            Conservatorio Giuseppe Verdi in Milan under the guidance of Luigi
            Magistrelli. Concurrently, he began studying opera singing with
            Davide Gualtieri, where he continues under the guidance of Gianluca
            Valenti. In 2017, he was admitted in the First diploma level Vocal
            Chamber Music course under the guidance of Daniela Uccello.`
              : `Il tenore italiano, Omar Mancini, si è laureato in 
              clarinetto nel 2016 al Conservatorio Giuseppe Verdi di Milano sotto 
              la guida di Luigi Magistrelli. Nel contempo, ha iniziato a studiare 
              canto d'opera con Davide Gualtieri, per poi continuare sotto la guida 
              di Gianluca Valenti. Nel 2017, è stato ammesso al È stato ammesso nel 
              corso di musica per la camera vocale a livello di diploma sotto la 
              guida di Daniela Uccello.`}
          </p>
          <p
            className={`bio-slice-p ${
              lang !== "EN" && "italiano"
            } px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5 mb-0`}
          >
            {lang === "EN"
              ? `In 2021, he graduated in Vocal Chamber Music with full marks and
            honours. In October 2018, he made his debut as a solo tenor in the
            Petite Messe Solennelle by Rossini as part of the 150th anniversary
            of the composer’s death.`
              : `Nel 2021, si è laureato in Musica da Camera Vocale a pieni voti.
              Ad Ottobre 2018 ha debuttato come solista tenore in Petite Messe 
              Solennelle di Rossini in occasione del 150simo anniversario dalla 
              morte del compositore.`}
          </p>
          <p
            className={`bio-slice-p ${
              lang !== "EN" && "italiano"
            } px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5 mb-0`}
          >
            {lang === "EN"
              ? `In September 2019, Omar made his debut in the Capri Opera Festival
            with the role of Rinuccio from Puccini’s Gianni Schicchi. Also in
            2019, he won first prize in the “Antonio Falconio Summer Edition”
            competition and the “Licitra Award” as best under 26 singer during
            the “Salvatore Licitra” opera singing competition. In 2020, he was a
            finalist in the Aslico competition, “Emerging Voices” section.`
              : `Nel Settembre del 2019, Omar ha debuttato al Capri Opera Festival 
              nel ruolo di Rinuccio in Puccini di Gianni Schicchi. Inoltre, sempre
              nel corso del 2019, ha vinto il primo premio nella competizione 
              “Antonio Falconio Summer Edition” insieme al “Licitra Award” come 
              miglior cantante under 26 durante la competizione di canto d'opera 
              “Salvatore Licitra”. Nel 2020, è stato finalista nella competizione 
              Asilico, sezione  “Voci Emergenti”.`}
          </p>
          <p
            className={`bio-slice-p ${
              lang !== "EN" && "italiano"
            } px-2 px-sm-4 px-md-5 px-lg-4 px-xl-5 mb-0`}
          >
            {lang === "EN"
              ? `Omar Mancini is currently a member of the Grand Théâtre de Genève’s
            Jeune Ensemble and sang as Roberto/Earl of Leicester (cover) in
            Donizetti’s Maria Stuarda, as Dritter Knappe in Wagner’s Parsifal
            and as Eurimaco in Monteverdi’s Il ritorno d’Ulisse in patria.`
              : `Omar Mancini fa attualmente parte del Grand Théâtre de Genève’s
              Jeune Ensemble e canta come Roberto/Earl di Lecester (cover) in Maria 
              Stuarda di Donizetti, come Dritter Knappe in Parsifal di Wagner ed 
              anche come Eurimaco in Il ritorno d'Ulisse in patria di Monteverdi.`}
          </p>
          <Button
            variant="outline-dark"
            className="px-5 py-3 mx-2 mx-sm-4 mx-md-5 mx-lg-4 mx-xl-5 learn-more-btn"
            onClick={() => navigate("/about-me")}
          >
            {lang === "EN" ? "Learn more" : "SCOPRI DI PIU'"}
          </Button>
        </div>
      </Col>
    </Row>
  );
};
