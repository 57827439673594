import { AboutMePageComponent } from "../about_me_page/AboutMePageComponent";
import { GalleryPageComponent } from "../gallery_page/GalleryPageComponent";
import { NotFoundPageComponent } from "../error_page/NotFoundPageComponent";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { HomepageComponent } from "../homepage/HomepageComponent";
import { LoadingComponent } from "../loading/LoadingComponent";
import { NavbarComponent } from "../navbar/NavbarComponent";
import { FooterComponent } from "../footer/FooterComponent";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";

export const AppWrapperComponent = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsLoading(true);
    // generate random number between 1000 - 2500 to simulate loading
    const random = Math.round(Math.random() * (2500 - 1000) + 1000);

    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, random);

    return () => {
      clearTimeout(timeout);
    };
  }, [location]);

  useEffect(() => {
    location.pathname === "/" && navigate("/homepage");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      fluid
      className={`p-0 app-container ${isLoading && "on-loading"}`}
    >
      <NavbarComponent />
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<></>} />
            <Route path="/homepage" element={<HomepageComponent />} />
            <Route
              path="/about-me"
              element={<AboutMePageComponent isLoading={isLoading} />}
            />
            <Route path="/gallery" element={<GalleryPageComponent />} />
            <Route path="*" element={<NotFoundPageComponent />} />
          </Routes>
          <FooterComponent />
        </>
      )}
      <ToastContainer />
    </Container>
  );
};
