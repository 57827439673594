import { OffCanvasComponent } from "../offcanvas/OffCanvasComponent";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { LangDropdownComponent } from "./LangDropdownComponent";
import Logo from "../../assets/images/Logo/Logo small.png";
import { Container, Nav, Navbar } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useReadLocalStorage } from "usehooks-ts";
import { Link } from "react-router-dom";
import { useState } from "react";

export const NavbarComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height } = useWindowDimensions();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const lang = useReadLocalStorage("lang");

  return (
    <Navbar expand="lg" expanded={collapsed} className="my-navbar" fixed="top">
      <Container fluid={width >= 992}>
        <Navbar.Brand as={Link} to="/homepage">
          <img src={Logo} alt="Omar Mancini Logo" className="nav-logo" />
        </Navbar.Brand>
        <span className="d-flex align-item-center">
          {width >= 476 && width < 992 && (
            <LangDropdownComponent
              collapsed={collapsed}
              setCollapsed={setCollapsed}
            />
          )}
          <Navbar.Toggle
            id="navbar-toggler-custom"
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          />
        </span>
        <Navbar.Collapse id="navbar">
          <Nav>
            {width > 992 ? (
              <>
                <LangDropdownComponent
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                />
                <Link to={"/homepage"} className="mx-3 nav-link">
                  Home
                </Link>
                <Link to="/about-me" className="mx-3 nav-link">
                  {lang === "EN" ? "About Me" : "Chi sono"}
                </Link>
                <Link to="/gallery" className="mx-3 nav-link">
                  {lang === "EN" ? "Gallery" : "Galleria"}
                </Link>
                <Link
                  to="https://youtube.com/@omarmancini160"
                  className="nav-link"
                >
                  Youtube
                </Link>
                <OffCanvasComponent />
              </>
            ) : (
              <>
                {width < 476 && (
                  <LangDropdownComponent
                    collapsed={collapsed}
                    setCollapsed={setCollapsed}
                  />
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    onClick={() => setCollapsed(false)}
                    to="/homepage"
                    className="nav-link"
                  >
                    Home
                  </Link>
                  <IoIosArrowForward className="nav-link-icon" />
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    onClick={() => setCollapsed(false)}
                    to="/about-me"
                    className="nav-link"
                  >
                    {lang === "EN" ? "About me" : "Chi Sono"}
                  </Link>
                  <IoIosArrowForward className="nav-link-icon" />
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    onClick={() => setCollapsed(false)}
                    to="/gallery"
                    className="nav-link"
                  >
                    {lang === "EN" ? "Gallery" : "Galleria"}
                  </Link>
                  <IoIosArrowForward className="nav-link-icon" />
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center">
                  <Link
                    onClick={() => setCollapsed(false)}
                    to="https://youtube.com/@omarmancini160"
                    aria-disabled
                    className="nav-link"
                  >
                    Youtube
                  </Link>
                  <IoIosArrowForward className="nav-link-icon" />
                </div>
                <hr />
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
