import { PlaysAndContactComponent } from "./plays_and_contact/PlaysAndContactComponent";
import { BiographyComponent } from "./biography/BiographyComponent";
import { AboutMeComponent } from "./about_me/AboutMeComponent";
import { SocialComponent } from "./social/SocialComponent";
import { NewsComponent } from "./news/NewsComponent";
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";

export const HomepageComponent = () => {
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    setIsReady(true);
  }, []);
  return (
    <>
      {isReady ? (
        <>
          <Container fluid className="p-0 home-container ">
            <AboutMeComponent />
          </Container>
          <Container>
            <BiographyComponent />
            <hr />
            <NewsComponent />
            <hr />
            <PlaysAndContactComponent />
            <SocialComponent />
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
