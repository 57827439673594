import { NewsCardComponent } from "./news_card/NewsCardComponent";
import news_it from "../../../assets/data/news_it.json";
import news_en from "../../../assets/data/news_en.json";
import { Swiper, SwiperSlide } from "swiper/react";
import { useReadLocalStorage } from "usehooks-ts";
import { Pagination, Autoplay } from "swiper";
import { Col, Row } from "react-bootstrap";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/thumbs";

export const NewsComponent = () => {
  const lang = useReadLocalStorage("lang");

  return (
    <Row className="mt-5 news-row">
      <Col xs={12} className="news-title-column mb-4">
        <h3>{lang === "EN" ? "News" : "Notizie"}</h3>
      </Col>
      <Col xs={12} className="news-swiper-container">
        <Swiper
          breakpoints={{
            250: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 35,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
          }}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          pagination={true}
          spaceBetween={10}
          modules={[Pagination, Autoplay]}
          className="news-swiper"
        >
          {lang === "EN"
            ? news_en.map((news) => (
                <SwiperSlide
                  className="d-flex justify-content-center"
                  key={news.id}
                >
                  <NewsCardComponent news={news} />
                </SwiperSlide>
              ))
            : news_it.map((news) => (
                <SwiperSlide
                  className="d-flex justify-content-center"
                  key={news.id}
                >
                  <NewsCardComponent news={news} />
                </SwiperSlide>
              ))}
        </Swiper>
      </Col>
    </Row>
  );
};
