import High10 from "../../assets/images/Photos/Gallery/HighRes/GalleryH10.jpg";
import Low10 from "../../assets/images/Photos/Gallery/LowRes/GalleryL10.jpg";
import High1 from "../../assets/images/Photos/Gallery/HighRes/GalleryH1.jpg";
import High2 from "../../assets/images/Photos/Gallery/HighRes/GalleryH2.jpg";
import High4 from "../../assets/images/Photos/Gallery/HighRes/GalleryH4.jpg";
import High5 from "../../assets/images/Photos/Gallery/HighRes/GalleryH5.jpg";
import High7 from "../../assets/images/Photos/Gallery/HighRes/GalleryH7.jpg";
import High8 from "../../assets/images/Photos/Gallery/HighRes/GalleryH8.jpg";
import High9 from "../../assets/images/Photos/Gallery/HighRes/GalleryH9.jpg";
import { Pagination, Autoplay, FreeMode, Navigation, Thumbs } from "swiper";
import Low1 from "../../assets/images/Photos/Gallery/LowRes/GalleryL1.jpg";
import Low2 from "../../assets/images/Photos/Gallery/LowRes/GalleryL2.jpg";
import Low4 from "../../assets/images/Photos/Gallery/LowRes/GalleryL4.jpg";
import Low5 from "../../assets/images/Photos/Gallery/LowRes/GalleryL5.jpg";
import Low7 from "../../assets/images/Photos/Gallery/LowRes/GalleryL7.jpg";
import Low8 from "../../assets/images/Photos/Gallery/LowRes/GalleryL8.jpg";
import Low9 from "../../assets/images/Photos/Gallery/LowRes/GalleryL9.jpg";
import { SocialComponent } from "../homepage/social/SocialComponent";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Button, Col, Container, Row } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Swiper, SwiperSlide } from "swiper/react";
import { useReadLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

export const GalleryPageComponent = () => {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { width, height } = useWindowDimensions();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [thumbSwiper, setThumbSwiper] = useState<any>(null);
  const [showFocus, setShowFocus] = useState<boolean>(false);
  const [focusedImage, setFocusedImage] = useState<string | null>(null);
  const lang = useReadLocalStorage("lang");

  const handleFocusedImage = (path: string) => {
    window.scrollTo(0, 0);
    setShowFocus(true);
    setTimeout(() => {
      window.scrollTo(0, 0);
      setFocusedImage(path);
    }, 850);
  };

  useEffect(() => {
    if (showFocus) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showFocus]);

  useEffect(() => {
    setIsReady(true);
  }, []);

  return (
    <>
      {isReady ? (
        <>
          <Row
            className={`gallery-title-row py-3 py-xl-5 mx-0 mb-5 ${
              showFocus && "go-hidden"
            }`}
          >
            <Col xs={12} className="py-5">
              <h2 className="mb-0">{lang === "EN" ? "The" : "La"}</h2>
              <h2 className="mb-0">{lang === "EN" ? "Gallery" : "Galleria"}</h2>
            </Col>
          </Row>
          <Container
            className={`d-flex flex-column align-items-center swiper-boot-container ${
              showFocus && "go-hidden"
            }`}
          >
            <Swiper
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              pagination={true}
              spaceBetween={10}
              navigation={width >= 768}
              thumbs={{ swiper: thumbSwiper }}
              modules={[Pagination, Autoplay, Navigation, Thumbs]}
              className="mySwiper2"
            >
              <SwiperSlide>
                <img
                  src={width < 992 ? Low1 : High1}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low1 : High1)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low2 : High2}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low2 : High2)}
                />
              </SwiperSlide>

              <SwiperSlide>
                <img
                  src={width < 992 ? Low4 : High4}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low4 : High4)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low5 : High5}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low5 : High5)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low7 : High7}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low7 : High7)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low8 : High8}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low8 : High8)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low9 : High9}
                  alt="Omar Mancini pic for gallery"
                  onClick={() => handleFocusedImage(width < 992 ? Low9 : High9)}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src={width < 992 ? Low10 : High10}
                  alt="Omar Mancini pic for gallery"
                  onClick={() =>
                    handleFocusedImage(width < 992 ? Low10 : High10)
                  }
                />
              </SwiperSlide>
            </Swiper>
            <Swiper
              onSwiper={setThumbSwiper}
              loop={true}
              breakpoints={{
                200: {
                  spaceBetween: 10,
                  slidesPerView: 4,
                },

                768: {
                  spaceBetween: 10,
                  slidesPerView: 5,
                },
              }}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src={Low1} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low2} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low4} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low5} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low7} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low8} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low9} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Low10} alt="Omar Mancini pic for gallery" />
              </SwiperSlide>
            </Swiper>

            <Row>
              <Col>
                <Button
                  variant="outline-dark"
                  className="gallery-homepage-btn py-4 px-5"
                  onClick={() => navigate("/homepage")}
                >
                  {lang === "EN" ? "GET BACK TO HOMEPAGE" : "TORNA ALLA HOME"}
                </Button>
              </Col>
            </Row>
            <SocialComponent />
          </Container>
          <div
            className={`image-focus d-flex align-items-center justify-content-center ${
              showFocus && "show-active"
            }`}
          >
            <AiOutlineCloseCircle
              className="image-focus-icon"
              onClick={() => {
                setShowFocus(false);
                setTimeout(() => setFocusedImage(null), 500);
              }}
            />
            {focusedImage ? (
              <img src={focusedImage} alt="Omar Mancini pic for gallery" />
            ) : (
              <span className="loader"></span>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
