import { ContactFormComponent } from "./ContactFormComponent";
import { useReadLocalStorage } from "usehooks-ts";
import { MdLocationPin } from "react-icons/md";
import { BsCalendar3 } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";

export const PlaysAndContactComponent = () => {
  const lang = useReadLocalStorage("lang");

  return (
    <Row className="p-0 mx-0 mt-4 ">
      <Col
        xs={12}
        lg={6}
        className="single-play-column p-0 d-flex justify-content-center justify-content-lg-start"
      >
        <div className="single-play d-flex align-items-end justify-content-center">
          <div className="my-next-play p-2 p-md-5">
            <h4>
              {lang === "EN"
                ? "Don't miss my next play!"
                : "La mia prossima esibizione!"}
            </h4>
            <div>
              <h6>VIAGGIO A REIMS</h6>
              <span className="d-flex align-items-center mb-2">
                <BsCalendar3 className="next-play-icon me-2" />
                <p className="mb-0">
                  {lang === "EN" ? "June 2023" : "Giugno 2023"}
                </p>
              </span>
              <span className="d-flex align-items-center mb-2">
                <MdLocationPin className="next-play-icon me-2" />
                <span>
                  <p className="mb-0">
                    {lang === "EN"
                      ? "Genève GE, Switzerland CH"
                      : "Ginevra GE, Svizzera - CH"}
                  </p>
                  <p className="mb-0"> Grand Théâtre de Genève’s</p>
                </span>
              </span>
            </div>
          </div>
        </div>
      </Col>
      <Col
        xs={12}
        lg={6}
        className="img-and-contact p-0 d-flex justify-content-center justify-content-lg-end"
      >
        <Row className="contact-row p-0 m-0 mt-4 mt-lg-0 ">
          <Col
            xs={12}
            className="contact-img-column p-0 d-flex align-items-start"
          >
            <div className="contact-img"></div>
          </Col>
          <Col
            xs={12}
            className="contact-form-column p-0 d-flex align-items-end"
          >
            <div className="contact-form">
              <ContactFormComponent />
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
