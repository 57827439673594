import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Container } from "react-bootstrap";
import { useReadLocalStorage } from "usehooks-ts";

export const NotFoundPageComponent = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const lang = useReadLocalStorage("lang");

  return (
    <Container className="py5 d-flex align-items-center justify-content-center error-container">
      <Col xs={10} sm={8} lg={6} className="error-column">
        <h2>Oops!</h2>
        <p className="mb-0">
          {lang === "EN" ? `Page not found.` : `Pagina non trovata.`}
        </p>
        <p>
          {lang === "EN"
            ? `Path: ${location} doesn't exists.`
            : `Il percorso: ${location} non esiste.`}
        </p>

        <Button
          variant="outline-dark"
          className="py-3 px-4 w-100 mt-4 mt-md-5"
          onClick={() => navigate("/homepage")}
        >
          {lang === "EN" ? "Torna alla home" : "Get back to homepage"}
        </Button>

        <h3 className="error-fof">404</h3>
      </Col>
    </Container>
  );
};
