import { useReadLocalStorage } from "usehooks-ts";
import { Col } from "react-bootstrap";

export const AboutBioComponent = () => {
  const lang = useReadLocalStorage("lang");
  return (
    <>
      <Col xs={12} md={6}>
        <div className="about-me-img"></div>
      </Col>
      <Col xs={12} md={6} className="d-md-flex align-items-center col-bio">
        <div className="about-me-bio px-3 mt-4 mt-md-0 ">
          <h4
            className={`${
              lang === "IT" ? "mb-4 mb-xl-5" : "mb-5"
            } d-none d-lg-block`}
          >
            {lang === "EN" ? "At the moment..." : "Attualmente..."}
          </h4>
          <span>
            {lang === "EN" ? (
              <p className="actual-about-text">
                Omar Mancini is currently a member of the Grand Théâtre de
                Genève’s Jeune Ensemble and sang as Roberto/Earl of Leicester
                (cover) in Donizetti’s{" "}
                <span className="text-bold">Maria Stuarda</span> , as Dritter
                Knappe in Wagner’s <span className="text-bold">Parsifal</span>{" "}
                and as Eurimaco in Monteverdi’s{" "}
                <span className="text-bold">Il ritorno d’Ulisse in patria</span>
                .
              </p>
            ) : (
              <p className="actual-about-text italiano">
                Omar Mancini fa attualmente parte del Grand Théâtre de Genève’s
                Jeune Ensemble e canta come Roberto/Earl di Leicester (cover) in{" "}
                <span className="text-bold">Maria Stuarda</span> di Donizetti,
                come Dritter Knappe in{" "}
                <span className="text-bold">Parsifal</span> di Wagner ed anche
                come Eurimaco ne{" "}
                <span className="text-bold">Il ritorno d’Ulisse in patria</span>{" "}
                di Monteverdi.
              </p>
            )}

            {lang === "EN" ? (
              <p className="actual-about-text">
                In June 2023, he performs the role of Abdallo in Verdi’s{" "}
                <span className="text-bold">Nabucco</span>, alongside Saioa
                Hernandez as Abigaille and Nicola Alaimo as Nabucco.
              </p>
            ) : (
              <p className="actual-about-text italiano">
                Nel Giungno 2023, performa nel ruolo di Abadallo in{" "}
                <span className="text-bold">Nabucco</span>, al fianco di Saioa
                Hernandez nelle veci di Abigaille e Nicola Alaimo come Nabucco.
              </p>
            )}

            {lang === "EN" ? (
              <p className="actual-about-text mb-md-0">
                In September 2023 will be Libenskopf in Rossini’s{" "}
                <span className="text-bold">Viaggio a Reims</span> at the
                Deustche Oper Berlin, and in the next season in Geneva will sing
                as Italianische Tenor in Strauss’s{" "}
                <span className="text-bold">Rosenkavalier</span>, Arbace in
                Mozart’s Idomeneo and Frère Helie in Massiaen’s{" "}
                <span className="text-bold">Saint François d’Assise</span>.
              </p>
            ) : (
              <p className="actual-about-text mb-md-0 italiano">
                Nel Settembre del 2023 interpreterà Libenskopf in{" "}
                <span className="text-bold">Viaggio a Reims</span> di Rossini
                alla Deustche Oper Berlin, e nella prossima stagione a Ginevra
                canterà come Italianische Tenor nell'opera di Strauss,{" "}
                <span className="text-bold">Rosenkavalier</span>, come Arbace in{" "}
                <span className="text-bold">Idomeneo</span> e come Frère Helie
                in <span className="text-bold">Saint François d’Assise</span> di
                Massiaen.
              </p>
            )}
          </span>
        </div>
      </Col>
    </>
  );
};
