import { useReadLocalStorage } from "usehooks-ts";
import { Col } from "react-bootstrap";

export const AboutPathComponent = () => {
  const lang = useReadLocalStorage("lang");

  return (
    <>
      <Col xs={12} lg={6} className="bio-by-year">
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2016</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? "Graduated in clarinet at the "
                : "Laurea in clarinetto al "}
              <span className="text-bold">Conservatorio Giuseppe Verdi</span>
              {lang === "EN"
                ? ` in Milan under the guidance of Luigi Magistrelli. Concurrently, he
              began studying opera singing with Davide Gualtieri, where he
              continues under the guidance of Gianluca Valenti.`
                : ` di Milano sotto la guida di Luigi Magistrelli.\nAl contempo inizia
                a studiare canto d'opera al fianco di Davide Gualtieri per poi continuare
                gli studi sotto la guida di Gianluca Valenti.`}
            </p>
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2017</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? `He was admitted in the First diploma level Vocal Chamber Music course under 
              the guidance of Daniela Uccello.`
                : `Viene ammesso al primo livello di diploma sotto la guida di Daniela Uccello.`}
            </p>
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2018</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? "He made his debut as a solo tenor in the"
                : "Ha debuttato come solista tenore nella "}
              <span className="text-bold">Petite Messe Solennelle</span>
              {lang === "EN"
                ? " by Rossini as part of the 150th anniversary of the composer’s death."
                : " di Rossini in occasione del 150° anniversario dalla scomparsa del compositore."}
            </p>
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2019</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? `Omar made his debut in the Capri Opera Festival with the role of
              Rinuccio from Puccini’s `
                : `Omar ha debuttato al Capri Opera Festival nel ruolo di Rinuccio in `}
              <span className="text-bold">Gianni Schicchi</span>
              {lang === "IT" && " di Puccini"}.
            </p>
            <p className="single-exp">
              {lang === "EN"
                ? `He Also won first prize in the “Antonio Falconio Summer Edition”
              competition and the “Licitra Award” as best under 26 singer during
              the “Salvatore Licitra” opera singing competition.`
                : `Inoltre, vince il primo premio nella competizione “Antonio Falconio Summer Edition”
                e il “Licitra Award” come miglior cantante under 26 nel corso della competizione “Salvatore Licitra”.`}
            </p>
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2020</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? "He was a finalist in the Aslico competition, “Emerging Voices” section."
                : "E' stato finalista nella competizione Aslico, nella sezione “Voci Emergenti”."}
            </p>
          </div>
        </div>
      </Col>
      <Col xs={12} lg={6} className="bio-by-year">
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2021</h6>
            <span className="small-row"></span>
          </div>
          <div>
            <p className="single-exp">
              {lang === "EN"
                ? "He graduated in Vocal Chamber Music with full marks and honours"
                : "Si è laureato in Musica da Camera Vocale a pieni voti."}
            </p>
            <p className="single-exp">
              {lang === "EN"
                ? `He was selected for the “Bottega Donizetti” at the “Donizetti
              Opera” where he made his debut in the Opera Show in the
              performance “C’erano una volta due bergamaschi…”.`
                : `E' stato scelto per la “Bottega Donizetti” al “Donizetti
                Opera” dove ha fatto il suo debutto nell'Opera Show con la performance
                in “C’erano una volta due bergamaschi…”`}
            </p>
            <p className="single-exp">
              {lang === "EN"
                ? `He also debuted at the Teatro Sociale di Como with the role of the
              Guardian in Giovanni Sollima’s`
                : `Ha inoltre debuttato al Teatro Sociale di Como nel ruolo del Guardiano in `}
              <span className="text-bold">Acquaprofonda</span>
              {lang === "IT" && " di Giovanni Sollima"}.
            </p>
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2022</h6>
            <span className="small-row"></span>
          </div>
          <div>
            {lang === "EN" ? (
              <p className="single-exp">
                He performed in Horatio/La Voix Imaginarie de Lèlio in Berlioz’s{" "}
                <span className="text-bold">Lelio ou le retour à la vie</span>{" "}
                (January 2022) and the role of Conte di Bandiera in Salieri's{" "}
                <span className="text-bold">La scuola de’ gelosi</span> (May
                2022), both at the Teatro Regio di Torino.
              </p>
            ) : (
              <p className="single-exp">
                Ha preso parte in Honoratio/La Voix Imaginarie de Lèlio in{" "}
                <span className="text-bold">Lelio ou le retour à la vie</span>{" "}
                di Berlioz (Gennaio 2022) - e nel ruolo di Conte di Bandiera in{" "}
                <span className="text-bold">La scuola de’ gelosi</span> di
                Salieri (Maggio 2022), entrambi al Teatro Regio di Torino.
              </p>
            )}
            <p className="single-exp">
              {lang === "EN"
                ? `He continued singing the role of Conte di Bandiera in a concert
              version at the Festival della Valle d’Itria`
                : `Ha continuato a cantare nel ruoli del Conte di Bandiera nella sua versione 
                'concerto' al Festival della Valle d'Itria.`}
            </p>
            {lang === "EN" ? (
              <p className="single-exp">
                During the Donizetti Festival 2022, he was the cover for tenor
                Javier Camarena in{" "}
                <span className="text-bold">La Favorite</span> (Fernand) and in{" "}
                <span className="text-bold">L’ajo nell’imbarazzo</span> (Il
                marchese Enrico)
              </p>
            ) : (
              <p className="single-exp">
                Durante il Donizetti Festival 2022, è stato la ?cover? per il
                Tenore Javier Camarena in{" "}
                <span className="text-bold">La Favorite</span> (Fernand) and in{" "}
                <span className="text-bold">L’ajo nell’imbarazzo</span> (Il
                marchese Enrico)
              </p>
            )}
          </div>
        </div>
        <div className="single-year">
          <div className="d-flex align-items-center">
            <h6 className="about-year m-0 me-3">2023</h6>
            <span className="small-row"></span>
          </div>
          <div>
            {lang === "EN" ? (
              <p className="single-exp">
                He performs the role of Abdallo in Verdi’s{" "}
                <span className="text-bold">Nabucco</span>, alongside Saioa
                Hernandez as Abigaille and Nicola Alaimo as Nabucco.
              </p>
            ) : (
              <p className="single-exp">
                Performa nel ruolo di Abadallo in{" "}
                <span className="text-bold">Nabucco</span> di Verdi, accanto a
                Saioa Hernandez come Abigaille ed a Nicola Alaimo come Nabucco.
              </p>
            )}
            {lang === "EN" ? (
              <p className="single-exp">
                He will be Libenskopf in Rossini’s{" "}
                <span className="text-bold">Viaggio a Reims</span> at the
                Deustche Oper Berlin, and in the next season in Geneva will sing
                as Italianische Tenor in Strauss’s{" "}
                <span className="text-bold">Rosenkavalier</span>, Arbace in
                Mozart’s <span className="text-bold">Idomeneo</span> and Frère
                Helie in Massiaen’s{" "}
                <span className="text-bold">Saint François d’Assise</span>.
              </p>
            ) : (
              <p className="single-exp">
                Avrà il ruolo di Libenskopf in{" "}
                <span className="text-bold">Viaggio a Reims</span> di Rossini al
                Deustche Oper Berlin, e nella prossima stagione a Ginevra
                canterà come Italianische Tenor in{" "}
                <span className="text-bold">Rosenkavalier</span> di Strauss,
                Arbace in <span className="text-bold">Idomeneo</span> di Mozart
                e come Frère Helie in{" "}
                <span className="text-bold">Saint François d’Assise</span> di
                Massiaen.
              </p>
            )}
          </div>
        </div>
      </Col>
    </>
  );
};
